.template-1-certificate-container {
    position: relative;
    padding-top: 133%;
    width: 100%;

    .certificate-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        color: #fff;

        .certificate-content {
            position: relative;
            padding: 20px;
            padding-top: 10%;
            min-height: 100%;
            width: 100%;
            min-height: 115%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            box-shadow: 2px 2px 22px -8px rgba(0, 0, 0, 0.25);
            background-color: #1f193d;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .certificate-frame {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .certificate-badge-container {
                position: relative;
                padding-top: 20%;
                width: 20%;
                margin-bottom: 5%;

                .certificate-badge {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .certificate-title-container {
                width: 80%;
                font-size: 85%;
                font-weight: 600;
                line-height: 150%;
                text-align: center;
            }

            .certificate-description-container {
                width: 95%;
                font-size: 60%;
                line-height: 18px;
                text-align: center;
            }

            .certificate-recievers-info-container {
                width: 60%;
                margin: 2.5% 0;
                text-align: center;

                .awarded-to-text {
                    font-size: 50%;
                    line-height: 18px;
                }

                .recievers-name {
                    font-weight: 600;
                    font-size: 75%;
                    line-height: 210%;
                }

                .social-url {
                    font-size: 60%;
                    line-height: 18px;
                }
            }

            .certificate-custom-attributes-container {
                width: 80%;
                margin: 2.5% 0;
                font-size: 90%;
                text-align: center;
                max-height: 20%;
                overflow: hidden;

                .attribute-label {
                    font-size: 60%;
                }

                .attribute-value {
                    font-size: 85%;
                    line-height: 210%;
                }
            }

            .certificate-date-container {
                width: 30%;
                text-align: center;

                .date {
                    font-size: 65%;
                    font-weight: 600;
                    line-height: 150%;
                }

                .hint-text {
                    font-size: 50%;
                    line-height: 75%;
                }
            }

            .certificate-signature-pane {
                width: 95%;
                display: flex;
                align-items: center;

                .signatures-container {
                    display: flex;
                    justify-content: space-evenly;
                    width: 100%;

                    .signature {
                        padding: 5%;

                        .signature-text {
                            font-size: 85%;
                            font-weight: 600;
                            line-height: 200%;
                            text-align: center;
                        }

                        .signature-image-container {
                            position: relative;
                            width: 100%;
                            padding-top: 44%;

                            .signature-image {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }

                        .signature-hint {
                            font-weight: 300;
                            font-size: 50%;
                            line-height: 75%;
                            text-align: center;
                        }
                    }
                }
            }

            .certificate-footer {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 60%;

                .qr-code {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 20px;
                }

                .org-info {
                    display: flex;
                    justify-content: space-around;

                    .org-logo-container {
                        position: relative;
                        padding-top: 20%;
                        width: 20%;

                        .org-logo {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .org-name {
                        font-weight: 600;
                        font-size: 100%;
                        line-height: 190%;
                    }

                    .org-verified-container {
                        position: relative;
                        padding-top: 8%;
                        width: 8%;

                        .org-verified-icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .org-web-url {
                    text-align: center;
                    font-weight: 300;
                    font-size: 50%;
                    line-height: 75%;
                    padding-top: 5px;
                    color: rgba(255, 255, 255, 0.5);
                    transform: translateX(6.5%);
                }
            }
        }
    }
}
