@font-face {
    font-family: 'Nexa Bold';
    src: url('/assets/font/Nexa Bold.otf');
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('/assets/font/Gotham Rounded Medium.otf');
}

@font-face {
    font-family: 'Destoria';
    src: url('/assets/font/Destoria.otf');
}

.template-3-certificate-container {
    position: relative;
    padding-top: 133%;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(/assets/certificate/template-3/mr-template-3-frame.svg) #1f193d center
            no-repeat;
        background-size: 95%;
        z-index: 0;
    }

    .certificate-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;

        .certificate-content {
            color: #fff;
            font-family: 'Gotham Rounded', 'Roboto';
            padding: 20px;
            height: 100%;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            box-shadow: 2px 2px 22px -8px rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .certificate-badge-container {
                position: relative;
                padding-top: 20%;
                width: 20%;

                .certificate-badge {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .certificate-title-container {
                width: 60%;
                font-size: 120%;
                line-height: 150%;
                font-family: 'Nexa Bold', 'Roboto';
                text-align: center;
            }

            .certificate-description-container {
                width: 95%;
                font-size: 55%;
                line-height: 15px;
                text-align: center;
            }

            .certificate-recievers-info-container {
                width: 60%;
                margin: 2.5% 0;
                text-align: center;

                .awarded-to-text {
                    font-size: 60%;
                    line-height: 18px;
                }

                .recievers-name {
                    font-size: 85%;
                    line-height: 210%;
                }

                .social-url {
                    font-size: 50%;
                    line-height: 18px;
                }
            }

            .certificate-custom-attributes-container {
                width: 80%;
                margin: 2.5% 0;
                font-size: 90%;
                text-align: center;
                max-height: 20%;
                overflow: hidden;

                .attribute-label {
                    font-size: 60%;
                }

                .attribute-value {
                    font-size: 85%;
                    line-height: 210%;
                }
            }

            .certificate-signature-pane {
                width: 95%;
                display: flex;
                align-items: center;

                .signatures-container {
                    display: flex;
                    justify-content: space-around;
                    align-items: flex-end;
                    width: 100%;

                    .signature-line {
                        margin: 5px 0;
                        border-top: 1px solid #fff;
                    }

                    .certificate-date-container {
                        text-align: center;

                        .date {
                            font-size: 65%;
                            line-height: 100%;
                        }

                        .hint-text {
                            font-size: 60%;
                            line-height: 75%;
                        }
                    }

                    .signature {
                        max-width: 80px;
                        max-height: 53px;

                        .signature-text {
                            font-family: 'Destoria', 'Roboto';
                            font-size: 95%;
                            line-height: 100%;
                            text-align: center;
                        }

                        .signature-image-container {
                            position: relative;
                            width: 100%;
                            padding-top: 44%;

                            .signature-image {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }

                        .signature-hint {
                            font-size: 60%;
                            line-height: 75%;
                            text-align: center;
                        }
                    }
                }
            }

            .certificate-footer {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 60%;

                .qr-code {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 20px;
                }

                .org-logo-wrapper {
                    position: relative;
                    padding-top: 40%;
                    width: 60%;

                    .org-logo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
