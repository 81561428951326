$primary: #24c3b5;

.tabs-root {
    width: 100%;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    .tab-item-wrapper {
        position: relative;
        background: #fff;
        padding: 0 2.5%;
        &.left-border {
            &::before {
                content: '';
                position: absolute;
                top: 20%;
                bottom: 20%;
                left: 0;
                width: 1px;
                border-left: 1px solid #dee2e6;
            }
        }
        &.right-border {
            &::after {
                content: '';
                position: absolute;
                top: 20%;
                bottom: 20%;
                right: 0;
                width: 1px;
                border-right: 1px solid #dee2e6;
            }
        }
        .tab-item {
            position: relative;
            padding: 1rem;
            cursor: pointer;
            color: rgba($color: #000000, $alpha: 0.25);
            font-weight: 600;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 6px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                background: $primary;
                transform: scaleX(0);
                transition: all 0.5s ease;
            }
            &.active {
                position: relative;
                color: rgba($color: #000000, $alpha: 1);
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    background: $primary;
                    transform: scaleX(1);
                }
            }
        }
    }
}
