.scroll-table {
    .table-responsive {
        min-height: 350px;
    }

    tbody {
        display: block;
        overflow: auto;
        &::before {
            content: '@';
            display: block;
            line-height: 1rem;
            text-indent: -999em;
        }

        scrollbar-width: thin;
        scrollbar-color: #888 transparent;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 1rem;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 1rem;
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #999;
        }
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
    }

    td {
        height: 4.6875rem;
        padding: 0 !important;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 0 0.75rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            &.rounded-top-left {
                border-top-left-radius: 10px;
            }
            &.rounded-top-right {
                border-top-right-radius: 10px;
            }
            &.rounded-bottom-left {
                border-bottom-left-radius: 10px;
            }
            &.rounded-bottom-right {
                border-bottom-right-radius: 10px;
            }
        }
    }
}
