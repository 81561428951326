.title-container {
    display: flex;
    align-items: center;

    .verified-icon {
        margin: 0 0.25rem;
        width: 0.75rem;
        height: 0.75rem;
    }
}
