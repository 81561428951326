.logo-root {
    position: relative;
    padding-top: 100%;
    width: 100%;
    &.full-size {
        .logo-container {
            max-width: unset;
            max-height: unset;
        }
        .action-btn-container {
            max-width: unset;
            max-height: unset;
        }
    }
    .logo-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        max-width: 7.5rem;
        max-height: 7.5rem;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
    }

    .action-btn-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-width: 7.5rem;
        max-height: 7.5rem;
        .editable-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border: none;
            outline: none;
            border-radius: 50%;
            text-indent: -999em;
            background: url(/assets/vector/mr-add-primary.svg) #fff center no-repeat;
            background-size: 75%;
            transition: all 0.5s ease-in;

            &.has-image {
                height: 20%;
                width: 20%;
                transform: translate(-50%, -50%);
            }
        }
        .deletable-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 20%;
            width: 20%;
            border: none;
            outline: none;
            border-radius: 50%;
            text-indent: -999em;
            background: url(/assets/vector/mr-delete.svg) #fff center no-repeat;
            background-size: 70%;
            transition: all 0.5s ease-in;
            transform: translate(50%, -50%);
        }
    }
}
