body {
    font-size: 0.875rem;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 0;

    .sidebar-sticky {
        display: flex;
        flex-direction: column;
        position: -webkit-sticky;
        position: sticky;
        height: calc(100vh);
        padding-top: 0.5rem;
        overflow-x: hidden;
        overflow-y: auto;
        /* Scrollable contents if viewport is shorter than content. */

        .nav-link {
            font-size: 112.5%;
            font-weight: 300;
            color: #656565;
            transition: all 0.2s ease;

            .feather {
                margin-right: 4px;
                color: #999;
            }

            &.active,
            &:hover {
                font-weight: 600;
            }

            &:hover .feather,
            &.active .feather {
                color: inherit;
            }
        }
    }
}

.sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar {
    z-index: 400 !important;
    margin-top: 1.5rem;
    background-color: #f6f6fc;

    .navbar-brand {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-size: 1rem;
        background-color: rgba(0, 0, 0, 0.25);
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
    }

    .form-control {
        padding: 0.75rem 1rem;
        border-width: 0;
        border-radius: 0;
    }

    .form-control-dark {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.1);

        &:focus {
            border-color: transparent;
            box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
        }
    }
}

/*
 * Utilities
 */

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

table tr:hover {
    cursor: pointer;
}

.blocks .btn-primary {
    margin: 0 5px;
    border-radius: 0;
}

.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: auto;
    text-align: center;

    .checkbox {
        font-weight: 400;
    }

    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;

        &:focus {
            z-index: 2;
        }
    }

    input {
        &[type='email'] {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &[type='password'] {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

/* React Datepicker customization */

.elevated-datepicker-popper {
    z-index: 30 !important;
}

.qr-checkbox {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    width: 100%;
    max-width: 480px;
    padding: 12px 10px 5px 10px;
    border-radius: 5px;
    cursor: pointer;

    label {
        padding-top: 3px;
        cursor: pointer;
    }
}

.sm-font-size {
    font-size: 0.8rem;
}
