#inviteReceiverModel {
    max-width: 400px;
    width: 100vw;

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input-wrap {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .title {
                margin-bottom: 0px;
                font-weight: 500;
            }
        }
    }
}
