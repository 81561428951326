.template-details {
    margin-bottom: 0.5rem;
    cursor: pointer;
    .template-wrapper {
        position: relative;
        padding-top: 50%;
        width: 100%;
        .template {
            position: absolute;
            top: 5%;
            left: 5%;
            width: 90%;
            height: 90%;
            object-fit: contain;
        }
    }
    .template-details-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2.5%;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out;
        .divider-line {
            margin: 5px 0;
            width: 0;
            border-top: 1px solid #fff;
            transition: width 0.2s ease-in-out;
        }
        .template-number {
            font-size: 75%;
            transform: translateY(100%);
            opacity: 0;
            transition: all 0.2s ease-in-out;
            transition-delay: 0.25s;
            max-width: 100%;
        }
        .template-name {
            font-weight: 600;
            font-size: 85%;
            transform: translateY(-100%);
            opacity: 0;
            transition: all 0.2s ease-in-out;
            transition-delay: 0.25s;
            max-width: 100%;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;

            .divider-line {
                width: 100%;
            }
            .template-number {
                transform: translateY(0);
                opacity: 1;
            }
            .template-name {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

.templates-container {
    position: relative;
    height: 100%;

    .templates-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        overflow: auto;
    }
}
