$primary: #24c3b5;
// Layout related

.add-btn {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    min-height: 4rem;
    min-width: 4rem;
    height: 5vw;
    width: 5vw;
    background: url(/assets/vector/mr-add-light.svg) $primary center no-repeat;
    background-size: 50%;
    box-shadow: 0px 12px 40px rgba(36, 195, 181, 0.4);
    border-radius: 50%;
    border: none;
    text-indent: -999em;
    z-index: 10;
}

.layout-main {
    overflow: auto;
    max-height: 100vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 5rem;
}

.btn-border-rounded-xl {
    border-radius: 0.5rem;
}

// Auth pages related styles

.signin-form-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);

    .helper-text-container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        .helper-text {
            font-weight: 500;
            color: #fff;
            font-size: 0.875rem;
        }
    }
}

.sign-in-form-container {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.signin-form-logo-container {
    flex-grow: 1;
    position: relative;
    width: 80%;
    padding-top: 60%;

    .signin-form-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        text-align: center;
    }
}

// Layout page related styles

.layout-navitem-logo-wrapper {
    position: relative;
    width: 30%;
    margin-left: 10%;
    padding-top: 30%;
    .layout-navitem-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        text-align: center;
    }
}

.layout-navitem-notifications-button {
    position: relative;
    margin: 0 1rem;
    width: 1.875rem;
    height: 1.875rem;
    border: none;
    outline: none;
    background: url(/assets/vector/mr-notification.svg) center no-repeat;
    background-size: contain;
    .dot {
        position: absolute;
        right: 0;
        top: 0;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background: $primary;
    }
}

.layout-navitem-user-info {
    display: flex;
    align-items: center;
    cursor: pointer;

    .user-info-text {
        display: block;
        padding: 0 0.25rem;
        .username {
            font-weight: 600;
        }
        .type {
            font-size: 70%;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .chevron-down {
        margin: 0 0.5rem;
        height: 1rem;
        width: 1rem;
    }

    .user-info-avatar {
        height: 3rem;
        width: 3rem;
        margin: 0 0.25rem;
        border-radius: 50%;
    }
}

// Org user related

.org-user-dashboard-home-profile-logo {
    height: 5rem;
    width: 5rem;
    object-fit: contain;
}

.org-user-dashboard-home-profile-icon-lg {
    height: 3rem;
    width: 3rem;
    object-fit: contain;
}

.org-user-dashboard-home-profile-btn {
    width: 100%;
    max-width: 20rem;
}

.org-user-dashboard-home-profile-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .org-user-dashboard-home-profile-logo {
        height: 5rem;
        width: 5rem;
    }
    .org-user-dashboard-home-profile-domain-name {
        font-size: 150%;
        font-weight: bold;
    }
}

.org-user-profile-action-btn {
    background: transparent;
    border: none;
    outline: none;
    .btn-image {
        height: 2rem;
        width: 2rem;
    }
}

.org-user-dashboard-home-user-details-logo {
    height: 8rem;
    width: 8rem;
    object-fit: contain;
}

.org-user-dashboard-home-user-details-delete-btn {
    height: 2.3rem;
    width: 2.3rem;
    background: url('/assets/vector/mr-delete.svg') center no-repeat;
    background-size: contain;
    border: none;
    outline: none;
    text-indent: -999em;
    border-radius: 50%;
}

// Org user - Elements related

.org-user-elements-action-btn {
    max-height: 1.75rem;
    max-width: 1.75rem;
}

.org-user-elements-img {
    max-height: 3.75rem;
    max-width: 3.75rem;
}

.org-user-elements-add-element-next-btn {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    padding: 0.5rem 3rem;
    font-weight: 600;
}

.org-user-element-certificate-btn-container {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.org-user-element-badge-preview-container {
    border-radius: 30px;
}

.org-user-element-icon-sm {
    height: 1rem;
    width: 1rem;
    border: none;
    outline: none;
    margin: 0 0.25rem;
    background: transparent;
    padding: 0;
    img {
        margin-top: -0.8rem;
    }
}
// Org user - Activites related

.org-user-activities-action-btn {
    max-height: 1.75rem;
    max-width: 1.75rem;
}

.org-user-activity-details-info-container {
    position: relative;
    flex-grow: 1;
    min-height: 10rem;
    .org-user-activity-details-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
}

// Org user - Receivers related

.org-user-receivers-action-btn {
    max-height: 1.75rem;
    max-width: 1.75rem;
}
