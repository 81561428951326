$primary: #24c3b5;

.paginated-table {
    .table-responsive {
        min-height: 350px;
    }
}

td {
    height: 4.6875rem;
    padding: 0 !important;
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0 0.75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        &.rounded-top-left {
            border-top-left-radius: 10px;
        }
        &.rounded-top-right {
            border-top-right-radius: 10px;
        }
        &.rounded-bottom-left {
            border-bottom-left-radius: 10px;
        }
        &.rounded-bottom-right {
            border-bottom-right-radius: 10px;
        }
    }
}

tbody::before {
    content: '@';
    display: block;
    line-height: 1rem;
    text-indent: -999em;
}

.pagination-wrap {
    display: flex;
    justify-content: flex-end;

    button {
        color: #24c3b5;

        &.Mui-selected {
            background-color: #24c3b530;
        }

        outline-color: $primary;
    }
}
