$body-bg: #f6f6fc;

$primary: #24c3b5;
$light-blue: rgba(55, 167, 255, 1);
$light-grey: rgba(246, 248, 255, 1);
$medium-grey: rgb(177, 172, 172);
$grey-2: rgba(54, 54, 72, 0.1);
$grey-3: rgba(54, 54, 72, 0.3);
$grey-8: rgb(54, 54, 72);
$success: rgba(0, 213, 146, 1);
$gradient-bg: linear-gradient(0deg, rgba(54, 88, 254, 1) 0%, $primary 70%);

$enable-responsive-font-sizes: true;

$font-family-sans-serif: 'Roboto';

$btn-padding-y: 1rem;
$btn-padding-x: 1rem;

$input-padding-x: 1rem;
$input-padding-y: 1rem;

$font-size-base: 1rem;
$link-color: #000;
$breadcrumb-bg: transparent;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import 'bootstrap/scss/bootstrap';
@import 'pages';
@import 'component';
@import './styles/issurerProfile.scss';
@import './styles/inviteReceiverModel';
