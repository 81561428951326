.prompt-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 1000;

    &.hidden {
        visibility: hidden;
    }

    .prompt {
        width: 100%;
        max-width: 700px;
        border-radius: 15px;
        background-color: white;
        padding: 1rem;

        .prompt-title {
            font-size: 120%;
            font-weight: bold;
            padding: 0.5rem 0;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        }
        .prompt-message {
            padding: 1rem 0;
        }
        .prompt-actions {
            display: flex;
            justify-content: end;
            .prompt-action-btn {
                margin: 0 0.5rem;
                max-width: 150px;
                width: 50%;
            }
        }
    }
}
