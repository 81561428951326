$primary: #24c3b5;

.nav-item {
    display: flex;
    align-items: center;
    height: 3.5rem;

    .nav-link {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 10%;
        .nav-icon {
            background-color: #656565;
            mask-repeat: no-repeat;
            mask-size: contain;
            height: 1rem;
            width: 1rem;
            margin: 0 1rem;

            &.logout {
                mask-image: url(/assets/vector/mr-logout.svg);
            }
        }
        &.active {
            font-weight: 600;
            color: $primary;

            .nav-icon {
                background-color: $primary;
            }
        }
        &::before {
            content: '';
            transition: all 0.5s ease;
            transform: translate(-50%, -50%) scale(0);
            will-change: auto;
        }
        &::after {
            content: '';
            transition: all 0.5s ease;
            transform: translate(-50%, -50%) scale(0);
            will-change: auto;
        }
        &.active::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            height: 25px;
            width: 25px;
            transform: translate(-50%, -50%) scale(1);
            border-radius: 50%;
            background: $primary;
            filter: blur(8px);
        }
        &.active::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            height: 25px;
            width: 25px;
            transform: translate(-50%, -50%) scale(1);
            border-radius: 50%;
            background: $primary;
        }
    }
}
