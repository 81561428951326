@font-face {
    font-family: 'Lato Bold';
    src: url('/assets/font/Lato Bold.ttf');
}

@font-face {
    font-family: 'Muli Regular';
    src: url('/assets/font/Muli Regular.ttf');
}

.template-4-certificate-container {
    position: relative;
    padding-top: 133%;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 99%;
        height: 105%;
        z-index: 0;
        background: url(/assets/certificate/template-4/mr-template-4-frame.svg) #fff center
            no-repeat;
        background-size: 100% 96%;
    }

    .certificate-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;

        .certificate-content {
            color: #7d7c7c;
            font-family: 'Muli Regular', 'Roboto';
            padding: 20px;
            min-height: 110%;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            box-shadow: 2px 2px 22px -8px rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            letter-spacing: 0.05rem;

            .certificate-badge-container {
                position: relative;
                padding-top: 20%;
                width: 20%;

                .certificate-badge {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .certificate-title-content {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
                margin-left: 15%;
                margin-top: 3%;

                .attendance-text {
                    font-size: 65%;
                    margin-left: 15%;
                    width: 70%;
                    text-align: right;
                    font-family: 'Lato', 'Roboto';
                    letter-spacing: 2px;
                }

                .awarded-to-text {
                    margin-left: 15%;
                    width: 70%;
                    text-align: center;
                    font-size: 75%;
                    margin-top: 7%;
                    letter-spacing: 2px;
                }
            }

            .certificate-title-container {
                padding-top: 12%;
                margin-left: 15%;
                width: 70%;
                font-size: 135%;
                line-height: 150%;
                font-family: 'Lato', 'Roboto';
                text-align: right;
                color: #fe4094;
            }

            .certificate-description-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            .certificate-description-container {
                width: 100%;
                font-size: 55%;
                text-align: center;
                word-break: normal;
                padding: 0 5% 0 5%;
            }

            .certificate-recievers-info-container {
                display: flex;
                flex-direction: column;
                gap: 20%;
                width: 65%;
                margin: 2.5% 0;
                text-align: center;
                margin-left: 15%;
                min-height: 35%;

                .recievers-name {
                    font-size: 65%;
                    border-bottom: 2px solid #dddddd;
                }

                .social-url {
                    font-size: 50%;
                }
            }

            .certificate-custom-attributes-container {
                width: 80%;
                margin: 2.5% 0;
                font-size: 90%;
                text-align: center;
                max-height: 20%;
                overflow: hidden;
                margin-left: 15%;

                .attribute-label {
                    font-size: 60%;
                }

                .attribute-value {
                    font-size: 85%;
                    line-height: 210%;
                }
            }

            .certificate-signature-pane {
                width: 95%;
                min-height: 100%;
                display: flex;
                gap: 40px;

                .signatures-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    gap: 50px;

                    .signature-line {
                        width: 100%;
                        margin: 5px 0;
                        border-bottom: 2px solid #dddddd;

                        &.signature-line-1 {
                            margin: 2px 0 5px;
                        }
                    }

                    .certificate-date-container {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 15%;

                        .date {
                            font-size: 50%;
                            line-height: 10%;
                        }

                        .hint-text {
                            line-height: 130%;
                            font-size: 50%;
                        }
                    }

                    .signature {
                        width: 100%;
                        max-height: 53px;

                        .signature-text {
                            max-width: 80px;
                            font-family: 'Muli Regular', 'Roboto';
                            font-size: 95%;
                            line-height: 100%;
                            text-align: center;
                        }

                        .signature-image-container {
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            position: relative;
                            width: 100%;
                            padding-top: 44%;

                            .signature-image {
                                position: absolute;
                                //top: 0;
                                width: 100%;
                                //height: 100%;
                                object-fit: contain;
                                max-width: 80px;
                            }
                        }

                        .signature-hint {
                            font-size: 45%;
                            text-align: center;
                        }
                    }
                }
            }

            .certificate-footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 5%;
                width: 80%;

                .certificate-footer-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 30px;
                    max-width: 40%;
                    width: 100%;
                }

                .qr-code {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                }

                .org-logo-wrapper {
                    position: relative;
                    padding-top: 40%;
                    width: 100%;

                    .org-logo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .template-4-certificate-container {
        .certificate-root {
            .certificate-content {
                .certificate-title-container {
                    font-size: 90%;
                }

                .certificate-title-content {
                    .attendance-text {
                        font-size: 40%;
                        letter-spacing: 2px;
                    }

                    .awarded-to-text {
                        font-size: 45%;
                    }
                }

                .certificate-recievers-info-container {
                    min-height: 20%;

                    .recievers-name {
                        font-size: 45%;
                        border-bottom: 2px solid #dddddd;
                    }
                }

                .certificate-description-container {
                    font-size: 40%;
                }

                .certificate-signature-pane {
                    width: 95%;
                    min-height: 100%;
                    display: flex;
                    gap: 30px;

                    .signatures-container {
                        gap: 15px;
                        grid-gap: 15px;

                        .signature {
                            flex-direction: column;
                            display: flex;
                            align-items: center;
                            margin-bottom: 15px;

                            .signature-image-container {
                                display: flex;
                                justify-content: center;
                                width: 70%;
                            }

                            .signature-text {
                                font-size: 50%;
                            }

                            .signature-hint {
                                font-size: 30%;
                                line-height: initial;
                            }
                        }

                        .certificate-date-container {
                            .date {
                                font-size: 30%;
                            }

                            .hint-text {
                                font-size: 30%;
                            }
                        }
                    }
                }

                .certificate-footer {
                    .qr-code {
                        canvas {
                            width: 50% !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
}
