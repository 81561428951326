#issuerProfile {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;

    .card {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 1200px;
        min-height: 60vh;

        .content {
            padding: 20px;
            width: 100%;
            display: flex;
            gap: 20px;
            justify-content: space-between;
        }

        .section {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;

            &.image-section {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .section-logo {
                width: 100%;
                max-width: 400px;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #issuerProfile {
        .card {
            .content {
                flex-direction: column;
            }
        }
    }
}
