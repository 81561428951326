.slider-wrapper {
    position: relative;
    width: 100%;
    .switch {
        display: inline-block;
        span {
            position: relative;
            width: 56px;
            height: 30px;
            float: left;
            input {
                display: none;
                &:checked + .slider {
                    background-color: #34c759;
                }
                &:checked + .slider:before {
                    transform: translateX(26px);
                }
                &:focus + .slider {
                    box-shadow: 0 0 1px #34c759;
                }
            }
        }
        label {
            line-height: 30px;
            margin-left: 12px;
        }
    }
    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border: 0;
        outline: none;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 30px;
        cursor: pointer;
        &:before {
            position: absolute;
            content: '';
            height: 22px;
            width: 22px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
        }
    }
}
