.paginated-table-head {
    border-radius: 0.5rem;
    th {
        height: 4.6875rem;
        padding: 0 !important;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 0 0.75rem;

            &.rounded-top-left {
                border-top-left-radius: 10px;
            }
            &.rounded-top-right {
                border-top-right-radius: 10px;
            }
            &.rounded-bottom-left {
                border-bottom-left-radius: 10px;
            }
            &.rounded-bottom-right {
                border-bottom-right-radius: 10px;
            }
        }
    }
}
.filter-icon-container {
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .filter-icon-search {
        height: 1rem;
        width: 1rem;
        background: url(/assets/vector/mr-search.svg) center no-repeat;
        background-size: contain;
    }
    .filter-icon-calendar {
        height: 1rem;
        width: 1rem;
        background: url(/assets/vector/mr-calendar.svg) center no-repeat;
        background-size: contain;
    }
    .filter-icon-close {
        height: 1rem;
        width: 1rem;
        border: none;
        outline: none;
        background: url(/assets/vector/mr-btn-close.svg) transparent center no-repeat;
        background-size: contain;
        text-indent: -999em;
    }
}
